/**
 * Calls in some intervals 'callback' until it returns true
 *
 * @param {Function} callback Function to be called repeatedly until it returns true
 * @param {number} [i] Internal counter of callback calls
 * @returns {void}
 */
export default function repeatFunctionUntilTrue(callback, i) {
    const index = i !== null && i !== void 0 ? i : 1;
    if (!callback() && index < 10) {
        // should wait
        const delay = 200 * index;
        setTimeout(() => {
            repeatFunctionUntilTrue(callback, 1 + index);
        }, delay);
    }
}
